import Splide from '@splidejs/splide';
window.splide = Splide;
window.lightbox = require('simple-lightbox');

import { navInit } from './modules/navigations';
import { homesiteSliders } from './modules/homesiteSliders';
import {galleryInit} from "../../includes/blocks/block-gallery/script";


navInit();
homesiteSliders();

if(document.querySelector('.blockGallery')){
  galleryInit();
}