export function navInit() {
  window.addEventListener("DOMContentLoaded", () => {

    const header = document.querySelector('.header');
    const cHamburger = header.querySelector('.c-hamburger');
    const navBox = header.querySelector('.header__navBox');

    cHamburger.addEventListener('click', () => {
      (cHamburger.classList.contains('is-active')) ? cHamburger.classList.remove('is-active') : cHamburger.classList.add('is-active');
      (navBox.classList.contains('is-active')) ? navBox.classList.remove('is-active') : navBox.classList.add('is-active');
    })

    if(window.screen.width < 1224) {
      const elements = document.querySelectorAll('.header li.menu-item-has-children');
      const arrowRight = `<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.0667 1.91666L7.46668 7.53333C7.40002 7.6 7.32779 7.64733 7.25002 7.67533C7.17224 7.70333 7.08891 7.71711 7.00002 7.71666C6.91113 7.71666 6.82779 7.70289 6.75002 7.67533C6.67224 7.64778 6.60002 7.60044 6.53335 7.53333L0.916683 1.91666C0.761127 1.76111 0.683349 1.56666 0.683349 1.33333C0.683349 1.1 0.766683 0.899998 0.933349 0.733332C1.10002 0.566665 1.29446 0.483332 1.51668 0.483332C1.7389 0.483332 1.93335 0.566665 2.10002 0.733332L7.00002 5.63333L11.9 0.733331C12.0556 0.577775 12.2473 0.499998 12.4753 0.499998C12.7033 0.499998 12.9005 0.583331 13.0667 0.749998C13.2334 0.916664 13.3167 1.11111 13.3167 1.33333C13.3167 1.55555 13.2334 1.75 13.0667 1.91666Z" fill="#21201B"/>
                          </svg>`;

      elements.forEach(element => {
        const item = element.querySelector('a');
        const spanArrow = document.createElement('span');
        spanArrow.setAttribute('class', 'arrow-right');
        spanArrow.innerHTML = arrowRight;
        item.appendChild(spanArrow);

        item.addEventListener("click", (e) => {
          e.preventDefault();
          if (item.classList.contains('open')) {
            item.classList.remove('open');
            element.querySelector('.sub-menu').classList.remove('open');
          } else {
            item.classList.add('open');
            element.querySelector('.sub-menu').classList.add('open');
          }
        })
      })
    }
  });
}
