export function galleryInit() {
  document.addEventListener( 'DOMContentLoaded', function () {
    let lightbox = new window.lightbox({
      elements: '.lightBoxRun'
    });
  });
}



